import React from "react"
import { Layout } from "../components"
import { CTALink } from "../components/CTALink"
import { TeamMembers } from "../components/about"
import "../css/about.css"

const About: React.FC = () => {
  return (
    <Layout currentSection="about">
      <Center>
        <AboutLivecycle />
        <OurSolution />
        <TheLivecycleTeam />
        <BecomeALivecycler />
        <SayHi />
      </Center>
    </Layout>
  )
}

const Center: React.FC = ({ children }) => {
  return <div className="flex flex-col items-center">{children}</div>
}

const WithTopDownBorders: React.FC<{ className?: string }> = ({
  children,
  className = "",
}) => {
  return (
    <div
      className={`border-black border-solid border-t-[5px] border-b-[5px] w-screen bg-[#ECF0FF] flex flex-col items-center ${className}`}
    >
      {children}
    </div>
  )
}

const WithFixedWith: React.FC<{ className?: string }> = ({
  children,
  className = "",
}) => {
  return (
    <div className={`max-w-[845px] w-screen px-6 flex flex-col ${className}`}>
      {children}
    </div>
  )
}

const AboutLivecycle = () => {
  return (
    <WithFixedWith className="mb-16 sm:mb-32">
      <h1>
        About <span className="text-lc-main-blue">Livecycle</span>
      </h1>
      <h3>Our story</h3>
      <p>
        The best technology is built <b>together</b>.
      </p>
      <p>
        But working within a group can also introduce bottlenecks, silos,
        miscommunications and messy feedback loops.
      </p>
      <p>
        For many dev teams, this means context-switches, delays, poor feedback
        and WAY too many meetings.
      </p>
      <p>
        We knew these challenges well, having worked with many development teams
        over the years.
      </p>
      <p>
        We looked for solutions to give our teams the product visibility and
        informational parity they needed. We tried to eliminate common workflow
        friction while still enabling everyone to work within their preferred
        context.
      </p>
      <p>There just has to be a better way, we thought.</p>
      <p>Well, it turns out there wasn't.</p>
      <p>So we got together and started a company to build one ourselves.</p>
    </WithFixedWith>
  )
}

const OurSolution = () => {
  return (
    <WithTopDownBorders>
      <WithFixedWith className="mt-26 mb-26">
        <h3>Our solution</h3>
        <p>
          Livecycle is an async collaboration platform for coders and other
          stakeholders. It's built with the development workflow in mind,
          enabling maximum visibility AND maximum team velocity.
        </p>
        <p>
          Every commit becomes a shareable, collaborative preview environment,
          accessible to everyone on the team. Review feedback is given
          contextually, on top of the product UI, and everything is synced to
          the code owner in the Git repository.
        </p>
        <p>
          You could say we're reimagining how development teams work together,
          but the truth is, we're just enabling them to do what they've been
          trying to do all along.
        </p>
        <p>So stop settling and "get your ship together" with Livecycle.</p>
        <p>Code more, align better and ship products faster, together.</p>
        <CTALink href="https://livecycle.dev" className="bg-black mt-12">
          Get Livecycle
        </CTALink>
      </WithFixedWith>
    </WithTopDownBorders>
  )
}

const TheLivecycleTeam = () => {
  return (
    <div className="mt-24 sm:mt-38 mb-24 sm:mb-38 w-full flex flex-col items-center">
      <h1 className="text-center leading-[2.7rem] sm:leading-[6rem] px-4">
        The <span className="text-lc-main-blue">Livecycle team</span>
      </h1>
      <TeamMembers />
    </div>
  )
}

const BecomeALivecycler = () => {
  return (
    <WithTopDownBorders>
      <div className="max-w-[960px] w-screen px-6 flex flex-col mt-16 sm:mt-38 mb-16 sm:mb-38 items-center">
        <h1 className="text-3xl sm:text-5xl lg:text-[5.67rem] mb-8 sm:mb-16">
          Become a Livecycler
        </h1>
        <p className="text-[1.375rem] sm:text-4xl lg:text-5xl leading-8 sm:leading-[4rem] md:sm:leading-[4rem] lg:sm:leading-[4rem] text-center">
          We're looking for some humble, curious humans to join our team and be
          a part of building something big.
        </p>
        <CTALink
          href="https://get-livecycle.notion.site/Senior-Software-Engineer-61e3d0bb46cd4997ae82bdb36cd967fd"
          className="bg-black mt-10 sm:mt-16"
          target="_blank"
        >
          See our open roles
        </CTALink>
      </div>
    </WithTopDownBorders>
  )
}

const SayHi = () => {
  return (
    <WithFixedWith className="mt-26 sm:mt-40 items-center">
      <h1 className="mb-4 sm:mb-7">Say hi.</h1>
      <a
        href="mailto:hello@livecycle.io"
        className="text-2xl sm:text-6xl sm:mb-11"
      >
        hello@livecycle.io
      </a>
      <p>Yigal Alon 94, Tel Aviv</p>
    </WithFixedWith>
  )
}

export default About
