import React from "react"
import Assaf from "../../images/svg/avatars/assaf.svg"
import Batsheva from "../../images/svg/avatars/batsheva.svg"
import Elya from "../../images/svg/avatars/elya.svg"
import Gad from "../../images/svg/avatars/gad.svg"
import Liza from "../../images/svg/avatars/liza.svg"
import Matan from "../../images/svg/avatars/matan.svg"
import Naor from "../../images/svg/avatars/naor.svg"
import NewMemberAvatar from "../../images/svg/avatars/new-member.svg"
import Yaara from "../../images/svg/avatars/yaara.svg"
import Yshay from "../../images/svg/avatars/yshay.svg"
import Zevi from "../../images/svg/avatars/zevi.svg"
import { TeamMember } from "./TeamMember"

export const TeamMembers: React.FC = () => {
  return (
    <div className="grid max-w-[1063px] w-full grid-cols-[repeat(auto-fill,minmax(150px,1fr))] sm:grid-cols-[repeat(auto-fill,minmax(250px,1fr))] justify-items-center px-4">
      <TeamMember
        Avatar={Matan}
        name="Matan Mishan"
        title="Co-founder & CEO"
        twitterLink="https://twitter.com/MishanMatan"
        linkedInLink="https://www.linkedin.com/in/matanmishan/"
      />
      <TeamMember
        Avatar={Assaf}
        name="Assaf Krintza"
        title="Co-founder & VP R&D"
        twitterLink="https://twitter.com/krinssaf"
        linkedInLink="https://www.linkedin.com/in/assaf-krintza/"
      />
      <TeamMember
        Avatar={Yshay}
        name="Yshay Yaacobi"
        title="Co-founder & CTO"
        twitterLink="https://twitter.com/Yshayy"
        linkedInLink="https://www.linkedin.com/in/yshayy/"
      />
      <TeamMember
        Avatar={Liza}
        name="Liza Listvinsky"
        title="Head of Design"
        linkedInLink="https://www.linkedin.com/in/lizalistvinsky/"
        twitterLink="https://twitter.com/LListvinsky"
      />
      <TeamMember
        Avatar={Zevi}
        name="Zevi Reinitz"
        title="Head of Marketing"
        twitterLink="https://twitter.com/ZeviReinitz"
        linkedInLink="https://www.linkedin.com/in/zevi/"
      />
      <TeamMember
        Avatar={Elya}
        name="Elya Bar-On"
        title="Founding Engineer"
        twitterLink="https://twitter.com/EliyaBar"
        linkedInLink="https://www.linkedin.com/in/elya-b-873668101/"
      />
      <TeamMember
        Avatar={Naor}
        name="Naor Zruk"
        title="Founding Engineer"
        twitterLink="https://twitter.com/ZrNaor"
        linkedInLink="https://www.linkedin.com/in/naor-zruk-04b056a0/"
      />
      <TeamMember
        Avatar={Gad}
        name="Gad Shalev"
        title="Founding Engineer"
        linkedInLink="https://www.linkedin.com/in/gad-shalev-2205b6119/"
      />
      <TeamMember
        Avatar={Yaara}
        name="Yaara Hendel"
        title="Head of Product"
        linkedInLink="https://www.linkedin.com/in/yaara-hendel-7b98306a/"
      />
      <TeamMember
        Avatar={Batsheva}
        name="Batsheva Mandel"
        title="Brand Design"
        linkedInLink="https://www.linkedin.com/in/batshevamandel/"
      />
      <NewMember />
    </div>
  )
}

const NewMember: React.FC = () => {
  return (
    <a
      className="flex flex-col items-center mb-14"
      target="_blank"
      href="https://get-livecycle.notion.site/Senior-Software-Engineer-61e3d0bb46cd4997ae82bdb36cd967fd"
    >
      <NewMemberAvatar className="mb-6 w-32 h-[136px] sm:w-48 sm:h-[205px]" />
      <p className="text-base text-black sm:text-2xl mb-[0.125rem]">
        This could be you
      </p>
      <p className="text-sm text-black sm:text-lg">We're hiring ...:)</p>
    </a>
  )
}
