import React from "react"
import LinkedInLogo from "../../images/svg/logos/linkedin.svg"
import TwitterLogo from "../../images/svg/logos/twitter.svg"
import type { SVGComponent } from "../../types"

interface Props {
  Avatar: SVGComponent
  name: string
  title: string
  linkedInLink: string
  twitterLink?: string
}

export const TeamMember: React.FC<Props> = ({
  Avatar,
  name,
  title,
  linkedInLink,
  twitterLink,
}) => {
  return (
    <div className="flex flex-col items-center mb-14">
      <Avatar className="mb-6 w-32 h-[136px] sm:w-48 sm:h-[205px]" />
      <p className="text-base sm:text-2xl mb-[0.125rem]">{name}</p>
      <p className="text-sm sm:text-lg text-center mb-4">{title}</p>
      <div className="flex flex-row">
        <a href={linkedInLink} className="mr-3" target="_blank">
          <LinkedInLogo className="w-5 h-5" />
        </a>
        {twitterLink && (
          <a href={twitterLink} target="_blank">
            <TwitterLogo className="w-5 h-5" />
          </a>
        )}
      </div>
    </div>
  )
}
